import React from 'react'
import Row from 'react-materialize/lib/Row'
import Appointment from './Appointment'


const Video = ({bookings}) => {


  return (
    <div>
      <Row>
        {
          bookings.map(booking => <Appointment key={booking.startdate} booking={booking} />
          )
        }         
      </Row>

    </div>
  )
}

export default Video
